<template lang="pug">
  main#main.account-password.d-flex.flex-grow-1.justify-content-center
    b-container
      b-row
        b-col.mx-auto(sm='12' md='10' lg='8' xl='6')
          router-view
</template>

<script>
export default {
  name: 'account-password',
}
</script>
